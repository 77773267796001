import { Button, Link } from "@chakra-ui/react";
import "./Buttons.css";

export const PrimaryButton = (props) => {
  return (
    <Button
      as={Link}
      href={props.link}
      className="primary-button"
      fontWeight="normal"
      borderRadius="xl"
      target="_blank"
      variant="unstyle"
      {...props}
    >
      {props.label}
    </Button>
  );
};
