import {
  Box,
  useColorMode,
  Icon,
  useBreakpointValue,
  IconButton,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { FaMoon, FaSun } from "react-icons/fa";

export const ThemeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const isMobileScreen = useBreakpointValue({ base: true, md: false });

  const themeSound = new Audio("effects/sound/mario_jump.mp3");

  const onThemeToggle = () => {
    toggleColorMode();
    // themeSound.play();
  };

  const themeToggleVariants = {
    visible: {
      opacity: 1,
      y: [0, -20, 0],
      scale: [1, 1.6, 1],
      rotate: 180,
      transition: { duration: 0.5 },
    },
    initial: { opacity: 0, y: 0 },
  };

  return (
    <AnimatePresence mode="popLayout" initial={false}>
      {colorMode === "light" ? (
        <Box
          key="toggle_moon"
          as={motion.div}
          variants={themeToggleVariants}
          animate="visible"
          initial="initial"
          exit="initial"
          className="toggler"
          onClick={onThemeToggle}
          mr={[2, 2, 5]}
          fontSize={isMobileScreen ? "xl" : "2xl"}
        >
          {isMobileScreen ? (
            <IconButton
              variant="secondary"
              color="var(--title-color)"
              icon={<FaMoon />}
              fontSize="inherit"
              style={{ transform: "rotate(180deg)" }}
              isRound
            />
          ) : (
            <Icon as={FaMoon} style={{ transform: "rotate(180deg)" }} />
          )}
        </Box>
      ) : (
        <Box
          key="toggle_sun"
          as={motion.div}
          variants={themeToggleVariants}
          animate="visible"
          initial="initial"
          exit="initial"
          className="toggler"
          onClick={onThemeToggle}
          mr={[2, 2, 5]}
          fontSize={isMobileScreen ? "xl" : "2xl"}
        >
          {isMobileScreen ? (
            <IconButton
              variant="secondary"
              color="var(--title-color)"
              icon={<FaSun />}
              fontSize="inherit"
              isRound
            />
          ) : (
            <Icon as={FaSun} style={{ transform: "rotate(180deg)" }} />
          )}
        </Box>
      )}
    </AnimatePresence>
  );
};
