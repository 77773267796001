import { Flex } from "@chakra-ui/react";
import { FloatingButton, Footer, NavBar } from "Components";
import { ScrollToTop } from "Components/UI/Wrapper/ScrollToTop";
import { AboutPage, HomePage, OtherApps, PhotosPage } from "Pages";
import { Route, Routes } from "react-router-dom";
import bg_image from "./Data/Images/bg-gradient.webp";
import "./App.css";
import {
  HOME_PATH,
  OTHER_APPS_PATH,
  PHOTOS_PATH,
  ABOUT_PAGE_PATH,
} from "endpoints";

function App() {
  return (
    <ScrollToTop>
      <NavBar />
      <Flex
        direction="column"
        className="body"
        color="primary_color"
        bgColor="transparent"
        zIndex={1}
        pos="relative"
        bg={`url(${bg_image}) center center`}
        bgSize="cover"
      >
        <Routes>
          <Route exact path={HOME_PATH} element={<HomePage />} />
          <Route exact path={PHOTOS_PATH} element={<PhotosPage />} />
          <Route exact path={OTHER_APPS_PATH} element={<OtherApps />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
        <Footer />
      </Flex>
    </ScrollToTop>
  );
}

export default App;
