import { Link, useLocation } from "react-router-dom";
import { ThemeToggle } from "Components";
import icon from "Data/Logo/icon.svg";
import "./NavBar.css";
import {
  IconButton,
  Flex,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { HOME_PATH, PHOTOS_PATH } from "endpoints";
import { FaBlogger, FaHome, FaImages } from "react-icons/fa";

export const NavBar = () => {
  const { pathname } = useLocation();
  const { colorMode } = useColorMode();
  const activeHome = pathname === HOME_PATH;
  const activePhotos = pathname === PHOTOS_PATH;

  const isMobileScreen = useBreakpointValue({ base: true, md: false });

  const iconButtonBgColor =
    colorMode === "dark" ? "rgba(255,255,255,.2)" : "rgba(0,0,0,.12)";

  return (
    <nav
      style={{
        backgroundColor:
          colorMode === "dark"
            ? "rgba(104, 101, 101, 0.15)"
            : "rgba(155, 155, 155, 0.1)",
      }}
    >
      <Link to={HOME_PATH}>
        <img src={icon} alt="icon" className="logo-icon" />
      </Link>
      <Flex className="menu" gap={isMobileScreen ? 5 : 10} align="center">
        <Link className={activeHome ? "active-nav" : ""} to={HOME_PATH}>
          {isMobileScreen ? (
            <IconButton
              variant="secondary"
              fontSize="xl"
              icon={<FaHome />}
              bgColor={activeHome ? iconButtonBgColor : ""}
              isRound
            />
          ) : (
            "Home"
          )}
        </Link>
        <Link className={activePhotos ? "active-nav" : ""} to={PHOTOS_PATH}>
          {isMobileScreen ? (
            <IconButton
              variant="secondary"
              fontSize="xl"
              icon={<FaImages />}
              bgColor={activePhotos ? iconButtonBgColor : ""}
              isRound
            />
          ) : (
            "Photos"
          )}
        </Link>
        <a
          href="https://saurabhchirde.hashnode.dev/"
          target="_blank"
          rel="noreferrer"
        >
          {isMobileScreen ? (
            <IconButton
              variant="secondary"
              fontSize="xl"
              icon={<FaBlogger />}
              isRound
            />
          ) : (
            "Blogs"
          )}
        </a>
        <ThemeToggle />
      </Flex>
    </nav>
  );
};
